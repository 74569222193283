import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Button, Divider, Dropdown } from 'antd'
import { DownOutlined, LoadingOutlined, LogoutOutlined } from '@ant-design/icons'

import { setGlobalState, useGlobalState } from '@/globalStore/globalStore'
import { apiLogout } from './apis'
import { popupSlot } from './utils'
import logo from '@/assets/logo.webp'
import styles from './styles.module.less'

/** 页面头 */
export const PageHeader = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const lang = useGlobalState(s => s.lang)
  const userInfo = useGlobalState(s => s.userInfo)
  const [logoutLoading, setLogoutLoading] = useState(false)

  const homeRoute = import.meta.env.VITE_APP_HOME_ROUTE_PATH

  const handleLogout = async () => {
    try {
      setLogoutLoading(true)
      await apiLogout()
    } finally {
      setLogoutLoading(false)
    }
  }

  return (
    <div className={classNames(styles.box, className)}>
      <div className={styles.left}>
        <img
          style={{ cursor: userInfo ? 'pointer' : 'default' }}
          src={logo}
          onClick={() => userInfo && navigate(homeRoute)}
        />
      </div>

      <div className={styles.middle}></div>

      <div className={styles.right}>
        <Dropdown
          placement="bottomRight"
          arrow
          menu={{
            items: [
              { key: 'zh', label: '中文' },
              { key: 'en', label: 'English' },
            ],
            onClick: ({ key }) => setGlobalState({ lang: key === 'zh' ? 'zh' : 'en' }),
          }}
        >
          <Button type="link">{lang === 'zh' ? '中文' : 'English'}</Button>
        </Dropdown>

        {userInfo && (
          <>
            <Divider type="vertical" />
            <Dropdown
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 'logout',
                    disabled: logoutLoading,
                    icon: logoutLoading ? <LoadingOutlined /> : <LogoutOutlined />,
                    label: t('9-nav.tui-chu-deng-lu'),
                  },
                ],
                onClick: ({ key }) => {
                  if (key === 'logout') handleLogout()
                },
              }}
            >
              <Button type="text">
                {userInfo.userName || userInfo.email}
                <DownOutlined />
              </Button>
            </Dropdown>
          </>
        )}
      </div>
      <popupSlot.Slot />
    </div>
  )
}
